import { PRODUCT_CODES as pc } from '@/constants'

export const BEDDING = [
    pc.AERO_QUILT,
    pc.BANDED_PERCALE_DUVET_SET,
    pc.BANDED_PERCALE_SHEET_SET,
    pc.CLOUD_MEMORY_FOAM_PILLOW,
    pc.DIAMOND_KNIT_BLANKET,
    pc.DOWN_ALTERNATIVE_COMFORTER,
    pc.DOWN_ALTERNATIVE_PILLOW,
    pc.EMBROIDERED_HOTEL_STYLE_SHEETS,
    pc.EMBROIDERED_SATEEN_DUVET_SET,
    pc.ESSENTIAL_PILLOWCASES,
    pc.ESSENTIAL_SHEET_SET,
    pc.FEATHERBED_MATTRESS_TOPPER,
    pc.FLANNEL_SHEET_SET,
    pc.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER,
    pc.HEAVYWEIGHT_COMFORTER,
    pc.HERRINGBONE_KNIT_BLANKET,
    pc.HIGH_DENSITY_FOAM_MATTRESS_TOPPER,
    pc.LATEX_MATTRESS_TOPPER,
    pc.LIGHTWEIGHT_DOWN_ALTERNATIVE_COMFORTER,
    pc.LINEN_DUVET_COVER_SET,
    pc.LINEN_PILLOWCASES,
    pc.LINEN_SHEET_SET,
    pc.LOFTON_PILLOWCASE_PAIR,
    pc.MEMORY_FOAM_PILLOW,
    pc.MICROCOIL_MATTRESS_TOPPER,
    pc.ORGANIC_COTTON_CHANNEL_QUILT,
    pc.ORGANIC_COTTON_PILLOWCASES,
    pc.ORGANIC_COTTON_SHEETS,
    pc.ORGANIC_MATTRESS_PAD,
    pc.ORGANIC_QUILTED_MATTRESS_TOPPER,
    pc.ORGANIC_QUILTED_PILLOW,
    pc.ORGANIC_SATEEN_DUVET_COVER_SET,
    pc.ORGANIC_VELVET_QUILT,
    pc.PERCALE_DUVET_COVER_SET,
    pc.PERCALE_PILLOWCASES,
    pc.PERCALE_SHEET_SET,
    pc.PLUSH_TOWELS,
    pc.SATEEN_COTTON_SHEET_SET,
    pc.SILK_EYE_MASK,
    pc.SWEATER_KNIT_BLANKET,
    pc.THE_SAATVA_PILLOW,
    pc.WAFFLE_KNIT_BLANKET,
    pc.WAFFLE_KNIT_DUVET_COVER_SET,
    pc.WAFFLE_TOWELS,
    pc.WATERPROOF_MATTRESS_PROTECTOR,
    pc.WEIGHTED_BLANKET,
    pc.WEIGHTED_SILK_EYE_MASK,
    pc.ESSENTIAL_DUVET_COVER_SET
]

export const BEDDING_BUNDLES = [
    pc.SATEEN_SHEETS_LATEX_PILLOW,
    pc.SATEEN_SHEETS_LATEX_PILLOW_PAD,
    pc.SATEEN_SHEETS_MEMORY_FOAM_PILLOW
]

export const BENCHES = [
    pc.RHONE_STORAGE_BENCH,
    pc.BRIENNE_CHANNEL_OTTOMAN,
    pc.CONSTANCE_TUFTED_OTTOMAN,
    pc.ARNO_CUSHIONED_BENCH,
    pc.LENA_CUSHIONED_BENCH
]

export const MATTRESSES = [
    pc.SAATVA_CLASSIC,
    pc.LOOM_AND_LEAF,
    pc.SAATVA_CONTOUR5,
    pc.ZENHAVEN,
    pc.SOLAIRE,
    pc.SAATVA_HD,
    pc.SAATVA_YOUTH,
    pc.SAATVA_LATEX_HYBRID,
    pc.MEMORY_FOAM_HYBRID,
    pc.SAATVA_RX,
    pc.DOG_BED,
    pc.DOG_BED_COVER,
    pc.CRIB_MATTRESS,
    pc.BUNK_TRUNDLE_YOUTH
]

export const BASES = [
    pc.ADJUSTABLE_BASE,
    pc.ADJUSTABLE_BASE_PLUS,
    pc.ADJUSTABLE_BASE_PLUS_SOLAIRE,
    pc.ADJUSTABLE_BASE_PLUS_BEDFRAMES,
    pc.FOUNDATION,
    pc.FOUNDATION_HD,
    pc.FOUNDATION_SOLAIRE,
    pc.FRAME,
    pc.ADJUSTABLE_FRAME
]


export const BEDFRAMES = [
    pc.ADRA_LEATHER,
    pc.AMALFI,
    pc.AMALFI_PLATFORM_BED,
    pc.AMALFI_WITH_STORAGE,
    pc.CALAIS,
    pc.CASSIS,
    pc.CASSIS_WITH_STORAGE,
    pc.COLOGNE_WOOD,
    pc.COPENHAGEN,
    pc.CORSICA_WOOD,
    pc.CRETE,
    pc.HALLE,
    pc.HALLE_WITH_STORAGE,
    pc.HALLE_STORAGE_PLATFORM_BED,
    pc.LUCERNE,
    pc.LYON,
    pc.LYON_WITH_STORAGE,
    pc.MARBELLA,
    pc.MARSEILLE,
    pc.MATERA_WOOD,
    pc.MERANO,
    pc.MILAN,
    pc.MINORI,
    pc.MINORI_WITH_STORAGE,
    pc.PORTO,
    pc.RHODES,
    pc.SANTORINI,
    pc.SANTORINI_PLATFORM_BED,
    pc.SIENA_LEATHER,
    pc.SYDNEY,
    pc.VALENCIA,
]

export const PLATFORM_BEDFRAMES = [
    pc.ADRA_LEATHER,
    pc.AMALFI_PLATFORM_BED,
    pc.AMALFI_WITH_STORAGE,
    pc.CALAIS,
    pc.CASSIS,
    pc.CASSIS_WITH_STORAGE,
    pc.COLOGNE_WOOD,
    pc.COPENHAGEN,
    pc.CORSICA_WOOD,
    pc.CRETE,
    pc.HALLE,
    pc.HALLE_WITH_STORAGE,
    pc.HALLE_STORAGE_PLATFORM_BED,
    pc.LUCERNE,
    pc.LYON,
    pc.LYON_WITH_STORAGE,
    pc.MATERA_WOOD,
    pc.MERANO,
    pc.MILAN,
    pc.MINORI_WITH_STORAGE,
    pc.PORTO,
    pc.RHODES,
    pc.SANTORINI,
    pc.SANTORINI_PLATFORM_BED,
    pc.SIENA_LEATHER,
    pc.SYDNEY,
    pc.VALENCIA,

]

export const PILLOWS = [
    pc.CLOUD_MEMORY_FOAM_PILLOW,
    pc.DOWN_ALTERNATIVE_PILLOW,
    pc.MEMORY_FOAM_PILLOW,
    pc.ORGANIC_QUILTED_PILLOW,
    pc.THE_SAATVA_PILLOW
]

export const CHAIRS = [
    pc.ANAIS_CHAIR,
    pc.COMO_SWIVEL_CHAIR,
    pc.LOGAN_CHAIR,
    pc.LUNA_SWIVEL_CHAIR
]

export const RUGS = [
    pc.ADAMAS_BEDROOM_RUG,
    pc.CIRRUS_BEDROOM_RUG,
    pc.KANAN_BEDROOM_RUG,
    pc.MYRA_BEDROOM_RUG,
    pc.NAVI_BEDROOM_RUG,
    pc.TERNA_BEDROOM_RUG
]

export const CANDLES = [
    pc.SCENTED_CANDLES,
    pc.SCENTED_VOTIVES,
]

export const TOWELS = [
    pc.PLUSH_TOWELS,
    pc.WAFFLE_TOWELS
]

export const FURNITURE = [
    ...BEDFRAMES,
    ...BASES,
    ...BENCHES,
    ...CHAIRS,
    ...RUGS,
    ...CANDLES
]

export const FRAME_TO_PLATFORM_SURPLUS_PRODUCT_CODES = [
    pc.AMALFI,
    pc.MARBELLA,
    pc.MARSEILLE,
    pc.MINORI
]

export const PRODUCTS_WITHOUT_PDPS = [
    pc.ESSENTIAL_SHAMS,
    pc.COMFORT_ADJUSTING_TOPPER,
    pc.VELVET_PICK_STITCH_SHAMS,
    pc.SIGNATURE_SHAMS,
    pc.WAFFLE_KNIT_SHAMS,
    pc.BANDED_PERCALE_SHAMS,
    pc.ORGANIC_COTTON_CHANNEL_QUILT_SHAMS,
    pc.ORGANIC_VELVET_DIAMOND_QUILT_SHAMS,
    pc.EMBROIDERED_SATEEN_SHAMS,
    pc.ORGANIC_SATEEN_SHAMS,
    pc.PERCALE_SHAMS,
    pc.LINEN_SHAMS,
    pc.CRIB_SHEETS,
    pc.OSLO,
    pc.FOUNDATION_HD,
    pc.ADJUSTABLE_FRAME,
    pc.NATURAL_RUG_PAD,
    pc.SAATVA_MODERN_FOAM,
    pc.DOG_BED_COVER,
    pc.TEST_PRODUCT,
    pc.EXTEND_WARRANTY
]
