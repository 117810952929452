// We're transitioning to use the full productCode in uppercase e.g. ORGANIC_COTTON_SHEETS
// These are the constants which should be used rather than hardcoded strings in the code.
// The most notable exception is in @/views/PDP/content/Content.jsx which uses hardcoded strings
// because Next/Webpack do not support variable interpolation in dynamic imports.

// Foundations, Bases, & Frames

export const ADJUSTABLE_BASE = 'adjustable-base'
export const ADJUSTABLE_BASE_PLUS = 'adjustable-base-plus'
export const ADJUSTABLE_BASE_PLUS_SOLAIRE = 'adjustable-base-plus-solaire'
export const ADJUSTABLE_BASE_PLUS_BEDFRAMES = 'adjustable-base-plus-bedframes'

export const FOUNDATION = 'foundation'
export const FOUNDATION_SOLAIRE = 'foundation-solaire'
export const FOUNDATION_HD = 'foundation-hd'

export const FRAME = '9035'
export const ADJUSTABLE_FRAME = 'adjustable-frame'


// Mattresses

export const BUNK_TRUNDLE_YOUTH = 'bunk-trundle-youth'
export const CRIB_MATTRESS = 'crib-mattress'
export const DOG_BED = 'dog-bed'
export const DOG_BED_COVER = 'dog-bed-cover'
export const LOOM_AND_LEAF = 'loom-and-leaf'
export const MEMORY_FOAM_HYBRID = 'memory-foam-hybrid'
export const SAATVA_CLASSIC = 'saatva-classic'
export const SAATVA_CONTOUR5 = 'saatva-contour5'
export const SAATVA_HD = 'saatva-hd'
export const SAATVA_MODERN_FOAM = 'saatva-modern-foam'
export const SAATVA_LATEX_HYBRID = 'saatva-latex-hybrid'
export const SAATVA_RX = 'saatva-rx'
export const SAATVA_YOUTH = 'saatva-youth'
export const SOLAIRE = 'solaire'
export const ZENHAVEN = 'zenhaven'


// Bed Frames

export const ADRA_LEATHER = 'adra-leather'
export const AMALFI = 'amalfi'
export const AMALFI_PLATFORM_BED = 'amalfi-platform-bed'
export const AMALFI_WITH_STORAGE = 'amalfi-with-storage'
export const CALAIS = 'calais'
export const CASSIS = 'cassis'
export const CASSIS_WITH_STORAGE = 'cassis-with-storage'
export const COLOGNE_WOOD = 'cologne-wood'
export const COPENHAGEN = 'copenhagen'
export const CORSICA_WOOD = 'corsica-wood'
export const CRETE = 'crete'
export const HALLE = 'halle'
export const HALLE_STORAGE_PLATFORM_BED = 'halle-storage-platform-bed'
export const HALLE_WITH_STORAGE = 'halle-with-storage'
export const LUCERNE = 'lucerne'
export const LYON = 'lyon'
export const LYON_WITH_STORAGE = 'lyon-with-storage'
export const MARBELLA = 'marbella'
export const MARSEILLE = 'marseille'
export const MATERA_WOOD = 'matera-wood'
export const MERANO = 'merano'
export const MILAN = 'milan'
export const MINORI = 'minori'
export const MINORI_WITH_STORAGE = 'minori-with-storage'
export const OSLO = 'oslo'
export const PORTO = 'porto'
export const RHODES = 'rhodes'
export const SANTORINI = 'santorini'
export const SANTORINI_PLATFORM_BED = 'santorini-platform-bed'
export const SIENA_LEATHER = 'siena-leather'
export const SYDNEY = 'sydney'
export const VALENCIA = 'valencia'


// Bedding

export const ORGANIC_COTTON_SHEETS = 'organic-cotton-sheets'
export const ORGANIC_COTTON_SHEETS_SOLAIRE = 'organic-cotton-sheets-solaire'
export const PERCALE_SHEET_SET = 'percale-sheet-set'
export const LINEN_SHEET_SET = 'linen-sheet-set'
export const CRIB_SHEETS = 'crib-sheets'
export const EMBROIDERED_HOTEL_STYLE_SHEETS = 'embroidered-hotel-style-sheets'
export const BANDED_PERCALE_SHEET_SET = 'banded-percale-sheet-set'
export const WAFFLE_KNIT_DUVET_COVER_SET = 'waffle-knit-duvet-cover-set'
export const FLANNEL_SHEET_SET = 'flannel-sheet-set'
export const FLANNEL_PILLOWCASES = 'flannel-pillowcases'
export const SATEEN_COTTON_SHEET_SET = 'sateen-cotton-sheet-set'
export const SATEEN_COTTON_PILLOWCASES = 'sateen-cotton-pillowcases'
export const ESSENTIAL_SHEET_SET = 'essential-sheet-set'

export const PERCALE_DUVET_COVER_SET = 'percale-duvet-cover-set'
export const PERCALE_SHAMS = 'percale-shams'
export const ORGANIC_SATEEN_DUVET_COVER_SET = 'organic-sateen-duvet-cover-set'
export const ORGANIC_SATEEN_SHAMS = 'organic-sateen-shams'
export const EMBROIDERED_SATEEN_DUVET_SET = 'embroidered-sateen-duvet-set'
export const EMBROIDERED_SATEEN_SHAMS = 'embroidered-sateen-shams'
export const EMBROIDERED_PILLOWCASES = 'embroidered-pillowcases'
export const LINEN_DUVET_COVER_SET = 'linen-duvet-cover-set'
export const LINEN_SHAMS = 'linen-shams'
export const BANDED_PERCALE_DUVET_SET = 'banded-percale-duvet-set'
export const BANDED_PERCALE_SHAMS = 'banded-percale-shams'
export const BANDED_PERCALE_PILLOWCASES = 'banded-percale-pillowcases'
export const ORGANIC_COTTON_CHANNEL_QUILT = 'organic-cotton-channel-quilt'
export const ORGANIC_COTTON_CHANNEL_QUILT_SHAMS = 'organic-cotton-channel-quilt-shams'
export const ORGANIC_VELVET_QUILT = 'organic-velvet-quilt'
export const ORGANIC_VELVET_DIAMOND_QUILT_SHAMS = 'organic-velvet-diamond-quilt-shams'
export const AERO_QUILT = 'aero-quilt'
export const DIAMOND_KNIT_BLANKET = 'diamond-knit-blanket'
export const HERRINGBONE_KNIT_BLANKET = 'herringbone-knit-blanket'
export const WAFFLE_KNIT_BLANKET = 'waffle-knit-blanket'
export const SWEATER_KNIT_BLANKET = 'sweater-knit-blanket'
export const ESSENTIAL_DUVET_COVER_SET = 'essential-duvet-cover-set'
export const SIGNATURE_DUVET_COVER_SET = 'signature-duvet-cover-set'
export const VELVET_PICK_STICH_QUILT = 'velvet-pick-stitch-quilt'
export const DOWN_ALTERNATIVE_COMFORTER = 'down-alternative-comforter'
export const LIGHTWEIGHT_DOWN_ALTERNATIVE_COMFORTER = 'lightweight-down-alternative-comforter'
export const HEAVYWEIGHT_COMFORTER = 'heavyweight-comforter'
export const VELVET_PICK_STITCH_SHAMS = 'velvet-pick-stitch-shams'
export const SIGNATURE_SHAMS = 'signature-shams'
export const WAFFLE_KNIT_SHAMS = 'waffle-knit-shams'
export const ESSENTIAL_SHAMS = 'essential-shams'
export const COMFORT_ADJUSTING_TOPPER = 'comfort-adjusting-topper'

export const ORGANIC_COTTON_PILLOWCASE_PAIR = 'organic-cotton-pillowcase-pair'
export const ORGANIC_COTTON_PILLOWCASES = 'organic-cotton-pillowcases'
export const PERCALE_PILLOWCASES = 'percale-pillowcases'
export const LINEN_PILLOWCASES = 'linen-pillowcases'
export const ESSENTIAL_PILLOWCASES = 'essential-pillowcases'
export const LOFTON_PILLOWCASE_PAIR = 'lofton-pillowcase-pair'
export const SILK_PILLOWCASE = 'silk-pillowcase'


// Pillows

export const THE_SAATVA_PILLOW = 'the-saatva-pillow'
export const DOWN_ALTERNATIVE_PILLOW = 'down-alternative-pillow'
export const MEMORY_FOAM_PILLOW = 'memory-foam-pillow'
export const CLOUD_MEMORY_FOAM_PILLOW = 'cloud-memory-foam-pillow'
export const ORGANIC_QUILTED_PILLOW = 'organic-quilted-pillow'


// Pads & Toppers

export const ORGANIC_MATTRESS_PAD = 'organic-mattress-pad'
export const NATURAL_RUG_PAD = 'natural-rug-pad'
export const WEIGHTED_BLANKET = 'weighted-blanket'
export const WATERPROOF_MATTRESS_PROTECTOR = 'waterproof-mattress-protector'
export const LATEX_MATTRESS_TOPPER = 'latex-mattress-topper'
export const GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER = 'graphite-memory-foam-mattress-topper'
export const HIGH_DENSITY_FOAM_MATTRESS_TOPPER = 'high-density-foam-mattress-topper'
export const MICROCOIL_MATTRESS_TOPPER = 'microcoil-mattress-topper'
export const FEATHERBED_MATTRESS_TOPPER = 'featherbed-mattress-topper'
export const ORGANIC_QUILTED_MATTRESS_TOPPER = 'organic-quilted-mattress-topper'


// Chairs and benches

export const RHONE_STORAGE_BENCH = 'rhone-storage-bench'
export const BRIENNE_CHANNEL_OTTOMAN = 'brienne-channel-ottoman'
export const CONSTANCE_TUFTED_OTTOMAN = 'constance-tufted-ottoman'
export const ARNO_CUSHIONED_BENCH = 'arno-cushioned-bench'
export const LENA_CUSHIONED_BENCH = 'lena-cushioned-bench'
export const ANAIS_CHAIR = 'anais-chair'
export const COMO_SWIVEL_CHAIR = 'como-swivel-chair'
export const LOGAN_CHAIR = 'logan-chair'
export const LUNA_SWIVEL_CHAIR = 'luna-swivel-chair'


// Bedroom & Bath

export const KANAN_BEDROOM_RUG = 'kanan-bedroom-rug'
export const TERNA_BEDROOM_RUG = 'terna-bedroom-rug'
export const ADAMAS_BEDROOM_RUG = 'adamas-bedroom-rug'
export const CIRRUS_BEDROOM_RUG = 'cirrus-bedroom-rug'
export const MYRA_BEDROOM_RUG = 'myra-bedroom-rug'
export const NAVI_BEDROOM_RUG = 'navi-bedroom-rug'

export const WAFFLE_TOWELS = 'waffle-towels'
export const PLUSH_TOWELS = 'plush-towels'
export const SCENTED_CANDLES = 'scented-candles'
export const SCENTED_VOTIVES = 'scented-votives'

export const SILK_EYE_MASK = 'silk-eye-mask'
export const WEIGHTED_SILK_EYE_MASK = 'weighted-silk-eye-mask'

// Bundles

export const SATEEN_SHEETS_LATEX_PILLOW = 'sateen-sheets-latex-pillow'
export const SATEEN_SHEETS_LATEX_PILLOW_PAD = 'sateen-sheets-latex-pillow-pad'
export const SATEEN_SHEETS_MEMORY_FOAM_PILLOW = 'sateen-sheets-memory-foam-pillow'
export const CLASSIC_STARTER_BUNDLE = 'classic-starter-bundle'

// Products without PDPs
export const TEST_PRODUCT = 'test-product'
export const EXTEND_WARRANTY = 'extend-warranty'
